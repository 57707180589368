<template>
  <v-row justify="center">
    <v-dialog :persistent="persistent" @input="updateStatus" :max-width="maxWidth" :fullscreen="fullscreen" :value="active">
      <v-card v-bind:class="contentClass" :light="light" :color="colorCard" class="card-popupVue">
        <v-toolbar class="sticky-toolbar" color="primary">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div>
          <slot></slot>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'vs-popup',
  props: {
    maxWidth: {
      default: '800px',
      required: false,
    },
    contentClass: {
      Type: String,
      default: '',
    },
    title: {
      Type: String,
      default: '',
    },
    active: {
      Type: Boolean,
      default: false,
    },
    fullscreen: {
      Type: Boolean,
      default: false,
    },
    light: {
      Type: Boolean,
      default: false,
    },
    colorCard: {
      Type: String,
      default: 'primary',
    },
    persistent: {
      Type: Boolean,
      default: false,
    },
  },
  methods: {
    updateStatus(val) {
      if (val === false) this.$emit('update:active', val);
    },
    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>

<style lang="scss">
.sticky-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
}
</style>
